
import Vue from "vue"
import type { Person } from "@/types/api/person"
import type { Session } from "@/types/api/program"

export default Vue.extend({
  props: {
    speaker: { type: Object as VuePropType<Person>, required: true },
    isPage: { type: Boolean, default: false },
  },

  data () {
    return {
      sessions: [] as Session[],
      isSessionsOpen: this.isPage,
    }
  },

  async fetch () {
    await this.fetchSessions()
  },

  computed: {
    job (): string {
      const { speaker } = this
      const { localize } = this.$fmt
      return [localize(speaker.company), localize(speaker.position)]
        .filter(Boolean).join(", ")
    },

    bio (): string {
      return this.$fmt.localize(this.speaker.biography)
    },
  },

  methods: {
    async fetchSessions () {
      const [res, err] = await this.$api.program.getSessions({
        params: { "filter[person]": this.speaker.alias },
      })
      if (err) return console.error(err)
      this.sessions = this.$api.helpers.getList(res)
        .sort((a: Session, b: Session) => a.start - b.start)
    },

    closeModal () { this.$emit("close") },
  },
})
